import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'ayhan-b7555.firebaseapp.com',
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: 'ayhan-b7555',
  storageBucket: 'ayhan-b7555.appspot.com',
  messagingSenderId: '',
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const provider = new GoogleAuthProvider(app);
export const storage = getStorage(app);
